import router from '@/router/index'
import { getToken } from '@/utils/auth'

router.beforeEach((to, from, next) => {
    let token = getToken()
    if(token){
        if(to.path=='/login'){
            next("/")
        }else{
            next()
        }
    }else{
        if(to.path=='/login'||to.path=='/admin/regedit'){
            next()
        }else{
            next('/login')
        }
    }
})